"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actual = actual;
exports.callList = callList;
exports.customerOnlineChange = customerOnlineChange;
exports.delQuickMessage = delQuickMessage;
exports.delQuickMessageClassify = delQuickMessageClassify;
exports.delReply = delReply;
exports.editNewScheduledPush = editNewScheduledPush;
exports.editQuickMessage = editQuickMessage;
exports.editQuickMessageClassify = editQuickMessageClassify;
exports.editReply = editReply;
exports.editScheduledPush = editScheduledPush;
exports.editSendWelfare = editSendWelfare;
exports.editSensitivewords = editSensitivewords;
exports.getMatchHistoryList = getMatchHistoryList;
exports.newScheduledPushList = newScheduledPushList;
exports.newScheduledPushSwitch = newScheduledPushSwitch;
exports.pushMsg = pushMsg;
exports.quickMessageClassifyList = quickMessageClassifyList;
exports.quickMessageList = quickMessageList;
exports.replyList = replyList;
exports.scheduledPushList = scheduledPushList;
exports.scheduledPushSwitch = scheduledPushSwitch;
exports.sendWelfareList = sendWelfareList;
exports.sensitivewords = sensitivewords;
exports.textList = textList;
exports.videoList = videoList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 获取文字聊天列表
 * @param {*} params
 */
function textList(params) {
  return (0, _request.default)({
    url: '/chat/text/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取用户通话订单
 * @param {*} params
 */
function videoList(params) {
  return (0, _request.default)({
    url: '/chat/video/list',
    method: 'GET',
    params: params
  });
}

/**
 * 获取通话记录
 * @param {*} params
 */
function callList(params) {
  return (0, _request.default)({
    url: '/chat/call/list',
    method: 'GET',
    params: params
  });
}

/**
 * 消息数据类型统计
 * @param {*} data
 */
function pushMsg(data) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/pushMsg',
    method: 'POST',
    data: data
  });
}

/**
 * 定时推送列表
 * @param {*} params
 */
function scheduledPushList(params) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/scheduledPushList',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑定时推送
 * @param {*} data
 */
function editScheduledPush(data) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/editScheduledPush',
    method: 'POST',
    data: data
  });
}

/**
 * 开启关闭定时推送
 * @param {*} data
 */
function scheduledPushSwitch(data) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/scheduledPushSwitch',
    method: 'POST',
    data: data
  });
}

/**
 * 定时推送列表
 * @param {*} params
 */
function newScheduledPushList(params) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/newScheduledPushList',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑定时推送
 * @param {*} data
 */
function editNewScheduledPush(data) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/editNewScheduledPush',
    method: 'POST',
    data: data
  });
}

/**
 * 开启关闭定时推送
 * @param {*} data
 */
function newScheduledPushSwitch(data) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/newScheduledPushSwitch',
    method: 'POST',
    data: data
  });
}

/**
 * 福利派送列表
 * @param {*} params
 */
function sendWelfareList(params) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/sendWelfareList',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑福利派送
 * @param {*} data
 */
function editSendWelfare(data) {
  return (0, _request.default)({
    url: '/tools/messageLibrary/editSendWelfare',
    method: 'POST',
    data: data
  });
}

/**
 * 实际流水查询
 * @param {*} params
 */
function actual(params) {
  return (0, _request.default)({
    url: '/chat/actual',
    method: 'GET',
    params: params
  });
}

/**
 * 客服配置
 * @param {*} params
 */
function replyList(params) {
  return (0, _request.default)({
    url: '/chat/reply',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑客服配置
 * @param {*} data
 */
function editReply(data) {
  return (0, _request.default)({
    url: '/chat/reply/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除客服配置
 * @param {*} data
 */
function delReply(data) {
  return (0, _request.default)({
    url: '/chat/reply/delete',
    method: 'post',
    data: data
  });
}

/**
 * 快捷回复
 * @param {*} params
 */
function quickMessageList(params) {
  return (0, _request.default)({
    url: '/chat/quickMessage',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑快捷回复
 * @param {*} data
 */
function editQuickMessage(data) {
  return (0, _request.default)({
    url: '/chat/quickMessage/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除快捷回复
 * @param {*} data
 */
function delQuickMessage(data) {
  return (0, _request.default)({
    url: '/chat/quickMessage/delete',
    method: 'post',
    data: data
  });
}

/**
 * 快捷回复分类列表
 * @param {*} params
 */
function quickMessageClassifyList(params) {
  return (0, _request.default)({
    url: '/chat/quickMessageClassify',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑快捷回复分类
 * @param {*} data
 */
function editQuickMessageClassify(data) {
  return (0, _request.default)({
    url: '/chat/quickMessageClassify/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除快捷回复分类
 * @param {*} data
 */
function delQuickMessageClassify(data) {
  return (0, _request.default)({
    url: '/chat/quickMessageClassify/delete',
    method: 'post',
    data: data
  });
}

/**
 * 敏感词列表
 * @param {*} params
 */
function sensitivewords(params) {
  return (0, _request.default)({
    url: '/chat/sensitivewords',
    method: 'GET',
    params: params
  });
}

/**
 * 添加敏感词
 * @param {*} data
 */
function editSensitivewords(data) {
  return (0, _request.default)({
    url: '/chat/sensitivewords/edit',
    method: 'post',
    data: data
  });
}

/**
 * 匹配历史列表
 * @param {*} params
 */
function getMatchHistoryList(params) {
  return (0, _request.default)({
    url: '/chat/getMatchHistoryList',
    method: 'GET',
    params: params
  });
}

/**
 * 客服上下线
 * @param {*} data
 */
function customerOnlineChange(data) {
  return (0, _request.default)({
    url: '/chat/customerOnlineChange',
    method: 'POST',
    data: data
  });
}